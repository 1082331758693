<template>
    <div class="residentdatabase-index-page">
        <div class="page-box">
            <div class="community-box">
                <div class="back-btn" v-if="Route.isBack()">
                    <Icon type="md-arrow-round-back" @click="Route.back()" size="24" />
                </div>
                <span style="padding-top:5px">选择社区：</span>
                <LiefengCascaderCity :orgCode="4401" :resetNum="0" @changeCasader="changeCasader" ref="cascaderCity" :value="orgCodeValue" :isShow="!!orgCodeValue"></LiefengCascaderCity>
                <Button type="primary" style="margin-left:10px" @click="searchBtn">查询</Button>
                <Button type="info" style="margin-left:10px" @click="resetBtn">重置</Button>
            </div>
            <div class="left-box">
                <div class="block-box menu-box">
                    <div class="item-box" v-for="(item, idx) in blocks" :key="idx" :class="{ pointer: !!item.router }" :style="{ 'background-image': 'url(' + item.background + ')' }" @click="jump(item.router)">
                        <div class="info-box" v-if="item.type === 'manage'">
                            <div class="info-item">
                                <p class="value">{{ toBeReviewedNum | processNum }}</p>
                            </div>
                            <div class="info-item">
                                <p class="value">{{ toBeSupplementedNum | processNum }}</p>
                            </div>
                        </div>
                        <div class="info-box" v-if="item.type === 'email'">
                            <div class="info-item">
                                <p class="value">{{ toBeRepliedNum | processNum }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-box type-box">
                    <div class="title">居民类型</div>
                    <div class="total-number">
                        <p class="name">当前社区居民总人数：</p>
                        <p class="value" @click="jump('/residentdatabasetable')">{{ userTypeData ? userTypeData.userCount : 0 }}</p>
                    </div>
                    <div class="statistics-box" v-if="userTypeData">
                        <div class="item-box" v-for="(item, idx) in userTypeData.userTypeCountList" :key="'userTypeCountList' + idx" @click="onJumpTableByAppId(item)">
                            <p class="title">{{ item.typeName }}</p>
                            <p class="value">{{ item.typeCount }}</p>
                            <div class="proportion">
                                <p>占社区</p>
                                <p>{{ item.percent }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="block-box block-row">
                <div class="statistics-age">
                    <div class="title">年龄分布</div>
                    <div class="age-chart" ref="age_chart"></div>
                    <div class="empty-box">暂无数据</div>
                </div>
                <div class="statistics-data">
                    <div class="title">基本数据统计</div>
                    <div class="data-box">
                        <div class="item-box">
                            <div class="value">155</div>
                            <div class="title">底册总数</div>
                        </div>
                        <div class="item-box">
                            <div class="value">237</div>
                            <div class="title">底册总数</div>
                        </div>
                        <div class="item-box">
                            <div class="value">378</div>
                            <div class="title">底册总数</div>
                        </div>
                        <div class="item-box">
                            <div class="value">800</div>
                            <div class="title">底册总数</div>
                        </div>

                        <div class="empty-box">暂无数据</div>
                    </div>
                </div>
            </div> -->
            </div>
            <div class="right-box">
                <div class="block-box common-functions">
                    <div class="title">
                        常用数据报表功能
                        <p class="entrance" @click="onJumpCustomized">全部功能</p>
                    </div>
                    <div class="functions-box">
                        <div class="item-box" v-for="(item, idx) in commonList" :key="'common' + idx" @click="onDisplayControl(item)">
                            <p class="name">{{ item.appName }}</p>
                            <Icon type="ios-arrow-forward" />
                        </div>

                        <div class="empty-box" v-if="!commonList || commonList.length <= 0">暂未设置常用功能</div>
                    </div>
                </div>
                <!-- <div class="block-box news-box">
                <div class="title">动态消息</div>
                <div class="list-box">
                    <div class="item-box" v-for="(item, idx) in 10" :key="idx">
                        <p class="content">这里有一条新消息</p>
                        <p class="look-btn">查看</p>
                    </div>
                    <div class="empty-box">暂无最新消息</div>
                </div>
            </div> -->
            </div>
        </div>

        <DataControl ref="data_control" :orgCode="orgCode" :orgName="orgName" @on-refresh="getCommonList"></DataControl>
    </div>
</template>

<script>
import * as echarts from "echarts"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"

import DataControl from "./data/control"
import Route from "../communitymobilization/entity/Route"

export default {
    components: { LiefengCascaderCity, DataControl },

    data() {
        return {
            Route: Route,

            blocks: [
                {
                    type: "manage",
                    router: () => {
                        if (!this.orgCode) {
                            this.$Message.warning("数据加载中...")
                            return false
                        }

                        return `/linehomemanage?name=residentdatabase&isLineHome=true&communityCode=${this.orgCode}`
                    },
                    background: "/images/residentdatabase_0.png",
                },
                {
                    type: "email",
                    router: () => `/emailindex?isLineHome=true&communityCode=${this.orgCode}`,
                    background: "/images/residentdatabase_1.png",
                },
                {
                    router: "/emailgroup?isLineHome=true",
                    background: "/images/residentdatabase_2.png",
                },
                {
                    router: "/verified?name=residentdatabase&isLineHome=true&page=true",
                    background: "/images/residentdatabase_3.png",
                },
                {
                    router: "/residentdatabasetable",
                    background: "/images/residentdatabase_4.png",
                },
                {
                    router: "/shopmanagementindex?isLineHome=true",
                    background: "/images/residentdatabase_5.png",
                },
                {
                    router: "/residentdatabasemybusiness?type=DYTX",
                    background: "/images/residentdatabase_6.png",
                },
                {
                    router: () => `/area?isLineHome=true&communityCode=${this.orgCode}`,
                    background: "/images/residentdatabase_7.png",
                },
                {
                    router: "/communitymobilization",
                    background: "/images/residentdatabase_8.png",
                },
                {
                    router: "/communitymobilization?c=personnel",
                    background: "/images/residentdatabase_9.png",
                },
                {
                    router: "/wechatgrouporgindex",
                    background: "/images/residentdatabase_10.png",
                },
                {
                    router: "/groupeasy",
                    background: "/images/residentdatabase_11.png",
                },
            ],

            // 待审核
            toBeReviewedNum: 0,
            // 待补充
            toBeSupplementedNum: 0,
            // 待回复
            toBeRepliedNum: 0,

            // 常用功能列表
            commonList: null,

            // 居民类型数据
            userTypeData: null,

            resetNum: 0,
            cascaderList: [],

            orgName: null,
            orgCode: null,
            // 初始化默认社区
            orgCodeValue: sessionStorage.getItem("RESIDENT_DATABASE_ORGCODE") || null,
        }
    },

    filters: {
        processNum(v) {
            return v > 99 ? "99+" : v
        },
    },

    watch: {
        orgCode() {
            this.getToBeReviewed()
            this.getToBeSupplemented()
            this.getToBeReplied()
            this.getCommonList()
            this.getUserTypeData()
        },
    },

    mounted() {
        this.getChart()
    },

    methods: {
        /**
         * 重置按钮
         */
        resetBtn() {
            this.resetNum++
            this.cascaderList = []
        },

        /**
         * 查询按钮
         */
        searchBtn() {
            if (this.cascaderList.length != 3) {
                return this.$Message.warning({
                    content: "请选择到社区再进行查询",
                    background: true,
                })
            }

            this.orgCode = this.cascaderList[this.cascaderList.length - 1]
        },

        changeCasader(val) {
            this.cascaderList = val

            if (val.length == 3) {
                let data = this.getOrgData(this.$refs.cascaderCity.CascaderList, val)
                this.orgName = data.orgName
                this.orgCode = data.orgCode
                // // 缓存 orgCode
                sessionStorage.setItem("RESIDENT_DATABASE_ORGCODE", this.cascaderList.join(","))
            }
        },

        /**
         * 根据 orgCode 获取 org 数据
         */
        getOrgData(list, val, idx = 0) {
            let key = val[idx]

            for (let i = 0; i < list.length; i++) {
                let v = list[i]

                if (v.orgCode === key) {
                    if (v.children && v.children.length > 0) {
                        return this.getOrgData(v.children, val, ++idx)
                    }

                    return v
                }
            }

            return {}
        },

        /**
         * 获取管理待审核条数
         */
        async getToBeReviewed() {
            return this.$get("/syaa/pc/sy/user/online/home/getFamilyMemberCount", {
                communityCode: this.orgCode,
                status: 1,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                inviterFlag: 1,
            }).then(res => {
                if (res.code == 200) {
                    this.toBeReviewedNum = res.data
                }
            })
        },

        /**
         * 获取管理待补充条数
         */
        async getToBeSupplemented() {
            return this.$get("/syaa/pc/sy/user/online/home/selectFamilyMemberReplenishRooNo", {
                orgCode: this.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.toBeSupplementedNum = res.data
                }
            })
        },

        /**
         * 获取邮箱待回复
         */
        getToBeReplied() {
            this.$get("/sendmsg/pc/chat/communityMessage/queryReplyLatestCount", {
                communityCode: this.orgCode,
                status: 1,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.toBeRepliedNum = res.data
                }
            })
        },

        /**
         * 获取常用功能列表
         */
        getCommonList() {
            this.$get("/syaa/api/syuser/pc/userBaseIndex/findIndexQuickByOrgCode", {
                communityCode: this.orgCode,
            }).then(res => {
                if (res.code == 200) {
                    this.commonList = res.dataList?.map(res => {
                        res.color = this.randomColor()
                        return res
                    })
                }
            })
        },

        /**
         * 获取居民类型列表
         */
        getUserTypeData() {
            this.$get("/syaa/api/syuser/pc/userBaseIndex/countUserType", {
                communityCode: this.orgCode,
            }).then(res => {
                if (res.code == 200) {
                    this.userTypeData = res.data
                }
            })
        },

        getChart() {
            echarts.init(this.$refs.age_chart).setOption({
                series: [
                    {
                        name: "Access From",
                        type: "pie",
                        radius: "50%",
                        data: [
                            { value: 1048, name: "Search Engine" },
                            { value: 735, name: "Direct" },
                            { value: 580, name: "Email" },
                            { value: 484, name: "Union Ads" },
                            { value: 300, name: "Video Ads" },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            })
        },

        /**
         * 跳转页面
         */
        jump(path) {
            if (!path) return

            if (typeof path === "function") {
                path = path()
            }

            // 路径为 false 时不执行
            if (path === false) return

            Route.jump(path)
        },

        /**
         * 显示控制页面
         */
        onDisplayControl(v) {
            this.$refs.data_control.display(v)
        },

        /**
         * 跳转到报表页面
         */
        onJumpTableByAppId(v) {
            // 数据为空时提示
            if (v.typeCount <= 0) {
                return this.$Modal.confirm({
                    title: `${v.typeName}数据为空`,
                    content: "您可前往常用功能的对应入口进入采集或导入",
                    okText: "立即前往",
                    onOk: () => {
                        this.$refs.data_control.display({
                            ...v,
                            appName: v.typeName,
                        })
                    },
                })
            }

            Route.jump(`/residentdatabasedatatable?id=${v.appId}&title=${v.typeName}&d=false&orgCode=${this.orgCode}&s=report&j=index`)
        },

        /**
         * 跳转至定制功能页面
         */
        onJumpCustomized() {
            if (!this.orgCode) {
                return this.$Message.warning({
                    content: "数据加载中，请稍后！",
                    background: true,
                })
            }

            Route.jump(`/residentdatabasemybusiness?orgCode=${this.orgCode}&orgName=${this.orgName}`)
        },

        randomColor() {
            var col = "#"
            for (var i = 0; i < 6; i++) col += parseInt(Math.random() * 12).toString(16)
            return col
        },
    },
}
</script>

<style lang="less">
.residentdatabase-index-page {
    padding: 20px;
    background: #f9f9f9;
    min-height: 100%;

    .page-box {
        margin: 0 auto;
        max-width: 1340px;
        display: flex;
        min-width: 900px;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    .community-box {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 20px;
        display: flex;

        .back-btn {
            cursor: pointer;
            margin-right: 20px;
            display: flex;
            align-items: center;
        }
    }

    .left-box {
        width: calc(100% - 295px);
    }

    .right-box {
        width: 280px;
        margin-left: 15px;
    }

    .block-box {
        width: 100%;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        margin: 15px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        > .title {
            padding: 10px 0;
            font-size: 20px;
            font-weight: bold;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .entrance {
                cursor: pointer;
                font-size: 14px;
                font-weight: normal;
            }
        }

        &.menu-box {
            display: flex;
            flex-wrap: wrap;

            .item-box {
                position: relative;
                width: calc(100% / 6 - 6px);
                margin: 3px;
                padding-bottom: 10%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                overflow: hidden;
                border-radius: 10px;

                &.pointer {
                    cursor: pointer;
                }

                .info-box {
                    position: absolute;
                    bottom: 10%;
                    left: 10%;
                    right: 0;

                    .info-item {
                        display: flex;
                        align-items: center;
                        margin: 3% 0;
                        white-space: nowrap;
                        overflow: hidden;

                        .value {
                            font-size: 16px;
                            line-height: 1;
                            color: rgb(30, 154, 253);
                        }

                        .name {
                            line-height: 1;
                            font-size: 14px;
                            margin-left: 10px;
                            color: #fff;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                @media only screen and (max-width: 1400px) {
                    .info-box {
                        .info-item {
                            .value,
                            .name {
                                font-size: 16px;
                            }

                            .name {
                                margin-left: 5px;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 1150px) {
                    .info-box {
                        .info-item {
                            .value,
                            .name {
                                font-size: 13px;
                            }

                            .name {
                                margin-left: 5px;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 1000px) {
                    .info-box {
                        bottom: 5%;
                        left: 6%;

                        .info-item {
                            .value,
                            .name {
                                font-size: 12px;
                            }

                            .name {
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }

        &.type-box {
            .total-number {
                margin-bottom: 10px;
                display: flex;
                align-items: flex-end;

                .name {
                    font-size: 18px;
                    color: #333;
                }

                .value {
                    cursor: pointer;
                    font-size: 36px;
                    line-height: 36px;
                    font-weight: bold;
                    color: #29cb97;
                }
            }

            .statistics-box {
                display: flex;
                margin: 20px 0 10px 0;
                flex-wrap: wrap;

                > .item-box {
                    cursor: pointer;
                    position: relative;
                    width: calc(100% / 6 - 10px);
                    max-width: 160px;
                    min-width: 120px;
                    padding: 10px;
                    margin: 5px;
                    height: 140px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    background: #f4fafd;
                    border-radius: 10px;
                    border: 1px solid #f5f5f5;
                    box-sizing: border-box;

                    &:last-child::after {
                        background: initial;
                    }

                    > .title {
                        font-size: 13px;
                        color: #666;
                        line-height: 20px;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        max-width: 100%;
                    }

                    .value {
                        font-size: 28px;
                        font-weight: bold;
                        color: #0091ff;
                    }

                    .proportion {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        color: #999;
                        font-size: 12px;
                    }
                }
            }
        }

        &.block-row {
            background: transparent;
            padding: 0;
            display: flex;

            .statistics-age,
            .statistics-data {
                background: #fff;
                padding: 10px;
                border-radius: 10px;

                > .title {
                    font-size: 26px;
                    font-weight: bold;
                    line-height: 40px;
                }

                .age-chart {
                    height: 200px;
                    width: 300px;
                }

                .empty-box {
                    width: 100%;
                    min-width: 300px;
                    padding: 80px 20px;
                    box-sizing: border-box;
                    text-align: center;
                }
            }

            .statistics-data {
                margin-left: 15px;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                .data-box {
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                    flex-wrap: wrap;

                    .item-box {
                        width: calc(100% / 4 - 10px);
                        min-width: 120px;
                        height: 120px;
                        margin: 5px;
                        background: #ecfff8;
                        display: flex;
                        flex-direction: column;
                        border-radius: 10px;
                        overflow: hidden;

                        > .value {
                            font-size: 28px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #0091ff;
                            flex-grow: 1;
                            font-weight: bold;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        > .title {
                            width: 100%;
                            height: 40px;
                            color: #fff;
                            background: #13e095;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        @media only screen and (max-width: 1100px) {
                            width: calc(100% / 2 - 10px);
                            min-width: initial;
                        }

                        @media only screen and (max-width: 950px) {
                            width: calc(100% - 10px);
                            min-width: initial;
                        }
                    }
                }
            }
        }

        &.common-functions {
            padding: 0;

            .title {
                padding: 15px 15px 5px 15px;

                .entrance {
                    color: #03b696;
                }
            }

            .functions-box {
                display: flex;
                flex-wrap: wrap;
                min-height: 500px;
                justify-content: space-between;
                align-content: flex-start;
                padding: 0 15px;
                max-height: 800px;
                overflow-y: auto;

                > .item-box {
                    cursor: pointer;
                    width: 100%;
                    margin: 5px 0;
                    padding: 0 10px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #fff;
                    color: #03b696;
                    border-radius: 6px;
                    border: 1px solid #f3f3f3;
                    box-sizing: border-box;
                }

                > .empty-box {
                    width: 100%;
                    padding: 80px 20px;
                    box-sizing: border-box;
                    text-align: center;
                }
            }
        }

        &.news-box {
            .list-box {
                min-height: 300px;

                > .item-box {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .content {
                        color: #666;
                        flex-grow: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .look-btn {
                        cursor: pointer;
                        flex-shrink: 0;
                        margin-left: 20px;
                        color: #22aa79;
                    }
                }

                > .empty-box {
                    padding: 200px 20px;
                    text-align: center;
                }
            }
        }
    }
}
</style>
